/* eslint-disable import/prefer-default-export, react/prop-types */
import React from 'react';

import TopLayout from './TopLayout';

export const onClientEntry = () => {
  window.addEventListener('load', () => {
    document.body.className = document.body.className.replace(/\bno-js\b/, '');
  });
};

export const wrapRootElement = ({ element }) => {
  return <TopLayout>{element}</TopLayout>;
};
